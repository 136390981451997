import React, {useEffect, memo} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet';
// import { markerIconPng } from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

// interface MapProps {
//     addresses: string[];
// }

//export default function Map({addresses}: MapProps) {
export default memo(function Map() {

    // const addresses = ["Reynolds Walk, Guelph, ON", "290 Bremner Blvd, Toronto, ON"]
    const popups = [
        [43.642690395331556, -79.38711044619014], // CN Tower
        [43.53304060550301, -80.22630914602537], // University of Guelph
        [43.55707335106224, -80.25321115387602], // Guelph General Hospital
        [43.4951274119409, -80.1800247290717], // Springfield Golf Club
        [43.521620016609624, -80.23350595506183], // Edinburgh Metro
        [46.20943461392341, -79.76429688389267], // Lake Nipissing
    ]

    const init_position = popups[1];

    // const provider = new OpenStreetMapProvider({
    //     params: {
    //         email: 'bsaund03@uoguelph.ca',
    //         'accept-language': 'en', // render results in English
    //         countrycodes: 'ca', // limit search results to Canada
    //         addressdetails: 0,
    //     }
    // });

    // const [coordinates, setCoordinates] = useState<LatLng[]>([]);
    // const [loading, setLoading] = useState(true);

    // Attempts to resolve human-readable addresses into
    // map coordinates.
    // const resolveCoordinates = async () => {
    //
    //     for(const address of addresses) {
    //
    //        console.log("Attemping:", address);
    //
    //        try {
    //
    //            const results = await provider.search({query: address});
    //
    //            if(results.length > 0) {
    //
    //                const result = results.at(0); // grab the first result
    //
    //                const coordinate = new LatLng(result.x, result.y);
    //
    //                if (!coordinates.includes(coordinate)) {
    //                    setCoordinates([...coordinates, new LatLng(result.x, result.y)]);
    //                }
    //
    //            } else {
    //                console.log("Found no results for address", address);
    //            }
    //
    //        } catch (error) {
    //            console.log(error);
    //        }
    //
    //     }
    //
    // }

    // useEffect(() => {
    //     resolveCoordinates();
    //     setLoading(false);
    // }, []); // empty dependency array causes this to only run once.

    const SearchField = () => {

        const provider = new OpenStreetMapProvider();

        const searchControl = new GeoSearchControl({
            provider: provider,
            showMarker: false,
            showPopup: false,
        });

        const map = useMap();

        useEffect(() => {
            map.addControl(searchControl);
            return () => map.removeControl(searchControl);
        }, []);

        return null;

    };

    return (
        <section className='map' data-testid='leaflet-map'>
            <MapContainer center={init_position} zoom={13} scrollWheelZoom={false}
                          style={{"width": "100%", "height": "100%"}}>
                <SearchField/>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

             {
               popups.map((popup, i) =>
                <Marker icon={new Icon({iconUrl: "/images/pin.png", iconSize: [32, 32], iconAnchor: [16, 32]})} position={popup} key={i}>
                  <Popup>
                    Customizable.
                  </Popup>
                </Marker>
             )}

            </MapContainer>
        </section>
    );
});
