import React, { useRef } from "react";
import ChevronDown from "../components/ChevronDown.tsx";
import TimePeriodSelector from "../components/TimePeriodSelector.tsx";
import Map from "../components/Map.tsx";

const Home: React.FC = () => {
    const mapRef = useRef<HTMLDivElement>(null);

    const scrollToMap = () => {
        mapRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div>
            <div style={{
                position: "static",
                width: "full",
                minHeight: "100vh",
                margin: "0",
                backgroundImage: 'url("/images/no-swimming.png")',
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflowX: "hidden",
                color: "white",
                boxShadow: "0 0 10px 4px black"
            }}>
                <div style={{
                    paddingTop: "5vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px"
                }}>
                    <img src="/images/logo.png" alt="WaterWize Logo" style={{width: "128px", height: "128px"}}/>
                    <h1 style={{
                        fontSize: "128px",
                        fontFamily: "Lato, sans-serif",
                        fontWeight: "600",
                        fontStyle: "semibold",
                        margin: "0"
                    }}>
                        WaterWize
                    </h1>
                </div>

                <p style={{
                    marginLeft: "10vw",
                    marginTop: "55vh",
                    fontSize: "30px",
                    width: "45%",
                    fontFamily: "merriweather-sans, sans-serif"
                }}>
                    Water quality is a serious concern. We provide a simple, intuitive way for anyone to view the correlation between Industrial wastewater, air pollution and water quality.
                </p>

                <div style={{ bottom: "15px", position: "absolute", width: "100%"}}>
                    <div 
                        onClick={scrollToMap}
                        style={{
                            flexDirection: "column",
                            display: "flex",
                            justifySelf: "center",
                            width: "fit-content",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        <p style={{
                            fontSize: "14px",
                            fontFamily: "merriweather-sans, sans-serif",
                            textAlign: "center",
                            marginBottom: "0",
                            color: "grey"
                        }}>
                            Scroll down
                        </p>
                        <div style={{marginLeft: "auto", marginRight: "auto"}}>
                            <ChevronDown color="grey"/>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{width: "full", minHeight: "95vh"}}>
                <h1 
                    ref={mapRef} 
                    style={{
                        textAlign: "center",
                        marginTop: "7vh",
                        fontSize: "68px",
                        fontFamily: "Lato, sans-serif",
                        fontWeight: "600",
                        fontStyle: "semibold",
                        color: "grey"
                    }}
                >
                    Interactive Map
                </h1>

                <div style={{
                    width: "70vw",
                    backgroundColor: "#E7F0FF",
                    height: "70vh",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "5vh",
                    boxShadow: "0 0 10px 4px #D0E3FF",
                }}>
                    <Map/>
                </div>
                    <TimePeriodSelector/>
            </div>
        </div>
    );
}

export default Home;